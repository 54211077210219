'use client';
import * as _ from 'lodash';
import React from 'react';
import Section from '@components/Atoms/Section';
import { ContactComponentSectionSchema } from '@lib/types/components/ContactComponentSectionSchema';
import TextWithImageSection from '@components/Sections/TextWithImageSection';
import { global } from 'styled-jsx/css';
import { GlobalProps } from '@lib/global';

export type Props = {
  component: ContactComponentSectionSchema;
  globals: GlobalProps;
};

export default function ContactUsSection({ globals, component }: Props) {
  const showIntercom = () => {
    // @ts-ignore
    (window as any).Intercom('show');
  };

  const mapCards = (cards: any[]) => {
    return cards.map((card, key): React.JSX.Element => {
      let link = (
        <a key={key} href={card.ButtonUrl} className="btn btn-outline-highlight fw-bold w-100 mb-3">
          {card.ButtonLabel}
        </a>
      );

      const isIntercom = card.ButtonUrl === 'openChat';

      if (isIntercom) {
        link = (
          <div
            key={key}
            onClick={() => {
              showIntercom();
            }}
            className="btn btn-outline-highlight fw-bold w-100 mb-3"
          >
            {card.ButtonLabel}
          </div>
        );
      }

      return link;
    });
  };

  const cardGroups = _.chunk(component.Contact, 3).map((cards, key) => {
    return (
      <div key={key} className="contact-items d-flex gap-3 justify-content-between flex-grow-1">
        {mapCards(cards)}
      </div>
    );
  });

  const textWithImage = !component.textWithImage ? (
    ''
  ) : (
    <TextWithImageSection
      className={'bg-transparent'}
      globals={globals}
      component={component.textWithImage}
    ></TextWithImageSection>
  );

  return (
    <Section name="contact">
      <div className="container pt-3 pb-3">
        <h1>{component.Title}</h1>
        <p>{component.Features}</p>

        {cardGroups}
      </div>

      {textWithImage}
    </Section>
  );
}
