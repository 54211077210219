import { CONFIG } from '@app/config';

export const getImageUrl = (url: string): string => {
  return CONFIG.cdnPath + url;
};

export type IconType = 'bank' | 'document' | 'dollar' | 'graph' | 'intersect' | 'user-gear';

export const getIconUrl = (icon: IconType): string => {
  return '/images/icons/' + icon + '.svg';
};

export const getLocalImageUrl = (url: string): string => {
  return '/images/' + url;
};
