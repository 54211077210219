// Vendor.
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { getLocalImageUrl } from "@lib/helpers/assetsHelpers";

// Prop types.
export type Props = {
  url: string;
  label: string;
};

/**
 * Link.
 */
export default function ReadMore({ url, label }: Props) {
  return (
    <div className="readMore">
      <Link href={url}>
        {label}
        <Image
          src={getLocalImageUrl("_icons/ArrowRight.svg")}
          alt=""
          width={20}
          height={20}
        />
      </Link>
    </div>
  );
}
