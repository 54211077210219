'use client';
import React from 'react';
import Section from '@components/Atoms/Section';
import { PricingComponentSectionSchema } from '@lib/types/components/PricingComponentSectionSchema';
import { openFaq } from '@app/features/faqs';
import { useDispatch } from 'react-redux';
import { querySelectorAll } from 'dom-helpers';

export type Props = {
  component: PricingComponentSectionSchema;
};

export default function PricingSection({ component }: Props) {
  const dispatch = useDispatch();
  const features = (features: string[], mobile: boolean) => {
    let classes = mobile
      ? 'd-flex d-lg-none align-items-start pb-4'
      : 'd-lg-flex d-none justify-content-center gap-3 pb-4 list-group-horizontal';
    classes += ' list-group gray mark-check';

    return <ul className={classes}>{mapGroup(features)}</ul>;
  };

  const openFaqItem = () => {
    // the top id defined on the container of layout which is navbar
    const section = querySelectorAll(document, 'section.faqs')[0];

    section.scrollIntoView({ behavior: 'smooth' });

    dispatch(openFaq(scrollToFaqId));
  };

  const mapGroup = (groups: string[]) => {
    return groups
      .filter(group => group.trim() !== '')
      .map((group, key) => {
        if (group.includes('*')) {
          let text = group.replace('*', '<span>*</span>');

          return (
            <li className="clickable" onClick={openFaqItem} key={key} dangerouslySetInnerHTML={{ __html: text }}></li>
          );
        } else {
          return <li key={key}>{group}</li>;
        }
      });
  };

  const pageFeatures = component.TopFeatures.split('\n') ?? [];

  const priceLabel = component.PriceLabel.split('\n');
  const priceLabelBelow = component.PriceLabelBelow.split('\n');
  const featureGroups = component.Features.split('---');

  const price = {
    title: component.Title,
    subtitle: component.Subtitle,
    price: component.Price,
    priceLabel1: priceLabel[0] ?? '',
    priceLabel2: priceLabel[1] ?? '',
    priceLabelBelow1: priceLabelBelow[0] ?? '',
    priceLabelBelow2: priceLabelBelow[1] ?? '',
    priceButtonLabel: component.ButtonLabel,
    priceButtonUrl: component.ButtonUrl,
    featureTitle: component.FeatureTitle,
    featureGroup1: mapGroup(featureGroups[0]?.split('\n') ?? []),
    featureGroup2: mapGroup(featureGroups[1]?.split('\n') ?? []),
  };

  const scrollToFaqId = component.FaqScrollId;

  let priceLabelBelow2 = <div className="fine-print mb-4">{price.priceLabelBelow2}</div>;

  if (scrollToFaqId) {
    priceLabelBelow2 = (
      <div
        className="clickable fine-print mb-4"
        onClick={openFaqItem}
        dangerouslySetInnerHTML={{ __html: price.priceLabelBelow2.replace('*', '<span>*</span>') }}
      ></div>
    );
  }

  return (
    <Section name="pricing-selector">
      <div className="container pt-3">
        <h1>{price.title}</h1>

        {features(pageFeatures, false)}

        <h2>{price.subtitle}</h2>

        <div className="card p-4 mb-4 mx-auto pricing-card">
          <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
            <h1 className="pb-0 mb-0">{price.price}</h1>
            <div className="text-start">
              <div>{price.priceLabel1}</div>
              <div>{price.priceLabel2}</div>
            </div>
          </div>
          <div className="fine-print">{price.priceLabelBelow1}</div>
          {priceLabelBelow2}

          <a href={price.priceButtonUrl} className="btn btn-highlight text-white fw-bold w-100">
            {price.priceButtonLabel}
          </a>
        </div>

        {features(pageFeatures, true)}

        <h2>{price.featureTitle}</h2>
        <div className="card mx-auto mb-3 d-flex features-card">
          <ul className="mark-check">{price.featureGroup1}</ul>
          <ul className="mark-check">{price.featureGroup2}</ul>
        </div>
      </div>
    </Section>
  );
}
