'use client';
import React, { useState } from 'react';
import { GlobalProps } from '@lib/global';
import { HeroComponentSectionSchema } from '@lib/types/components/HeroComponentSectionSchema';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import { useRouter } from 'next/navigation';
import Section from '@components/Atoms/Section';
import { mapLines } from '@lib/helpers/parsers';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: HeroComponentSectionSchema;
};

export default function Hero({ component, globals }: Props) {
  const navigate = useRouter();

  const [email, setEmail] = useState('');
  const onSubmit = (e: any) => {
    e.preventDefault();
    navigate.push(globals.translations.signupUrl + '&mail=' + email);
  };

  const htmlTitle = component.Title.replaceAll('\\n', '<br>');

  //   .replace('{n}', '<br>').replaceAll(
  //   /__(.*?)__/g,
  //   "<span class='text-highlight-darkblue'>$1</span>"
  // );

  const features = mapLines(component.Features, (item, key) => {
    return <li key={key}>{item}</li>;
  });

  const checks = mapLines(component.Checks, (item, key) => {
    return <li key={key}>{item}</li>;
  });

  return (
    <div className="wrapper-hero">
      <Section name="homepage-hero">
        <div className="container">
          <div className="d-flex initial-state">
            <div className="hero-left flex-grow-1">
              <strong>{component.Topheader}</strong>
              <h1 dangerouslySetInnerHTML={{ __html: htmlTitle }}></h1>
              <ul className="list-group mark-check mb-3 subheader-features">{features}</ul>

              <div className="d-flex buttons">
                <a href={component.PrimaryButtonUrl} className="btn btn-highlight btn-flex text-white cta-btn">
                  {component.PrimaryButtonLabel}
                </a>
                <a href={component.SecondaryButtonUrl} className="btn btn-secondary btn-flex text-white cta-btn">
                  {component.SecondaryButtonLabel}
                </a>
              </div>

              <ul className="list-group list-group-benefits mark-check checks">{checks}</ul>
            </div>

            <div className="hero-right">
              <img src={component.HeroImage.data?.attributes.url} alt="Hero" />
              {/*<img*/}
              {/*  className="d-none"*/}
              {/*  data-bs-toggle="modal"*/}
              {/*  data-bs-target="#modal-youtube-video"*/}
              {/*  id="img-arrow-play"*/}
              {/*  src="/images/icons/icon-play.svg"*/}
              {/*  alt=""*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
