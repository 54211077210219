import React from 'react';
import { GlobalProps } from '@lib/global';
import { getImageUrl } from '@lib/helpers/assetsHelpers';
import Section from '@components/Atoms/Section';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { TextWithImageComponentSectionSchema } from '@app/lib/types/components/TextWithImageComponentSectionSchema';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: TextWithImageComponentSectionSchema;
};

export default function TextWithImageSection({ component, className }: Props) {
  const titleFormatted = component.Title.replaceAll(/__(.*?)__/g, "<span class='text-highlight-yellow'>$1</span>");

  const rowClasses = component.ImagePosition === 'right' ? 'row d-lg-flex flex-row-reverse' : 'row';

  const imgCaption = component.ImageCaption ? (
    <p dangerouslySetInnerHTML={{ __html: component.ImageCaption }}></p>
  ) : null;

  return (
    <Section name="text-with-image" classes={className}>
      <div className="container pt-5 pb-5">
        <div className={rowClasses}>
          <div className="box-img col-sm-24 col-md-14">
            <img
              className="w-100"
              src={getImageUrl(component.Image?.data?.attributes.url ?? '')}
              alt={component.Title}
            />
            {imgCaption}
          </div>
          <div className="box-text col-sm-24 col-md-10">
            <h2 dangerouslySetInnerHTML={{ __html: titleFormatted }}></h2>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{component.Text}</ReactMarkdown>
          </div>
        </div>
      </div>
    </Section>
  );
}
