// Vendor.
import React from 'react';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';

// Prop types.
export type Props = {
  name?: string;
  children?: any;
  classes?: string;
};

/**
 * Link.
 */
export default function Section({ children, name, classes }: Props) {
  let addedClasses = `spaced ${name}`;

  let style = undefined;

  if (
    name === 'homepage-hero' ||
    name == 'pricing-selector' ||
    name == 'roadmap' ||
    name == 'schedule-call' ||
    name == 'contact'
  ) {
    style = {
      backgroundImage: `url('${getLocalImageUrl('icons-dark.webp')}')`,
    };

    classes += ' bg-icons';
  }

  addedClasses += ' ' + classes;

  return (
    <section style={style} className={addedClasses}>
      {children}
    </section>
  );
}
