// Vendor.
import React from "react";

// Prop types.
export type Props = {
  color?: "primary" | "secondary";
  children?: any;
};

/**
 * Link.
 */
export default function SectionTitle({ children, color }: Props) {
  const c = color ? `text-${color}` : "text-primary";
  const classes = `text-center mb-3 ${c}`;

  return <h2 className={classes}>{children}</h2>;
}
