import React from 'react';
import { GlobalProps } from '@lib/global';
import { TestimonialsComponentSectionSchema } from '@lib/types/components/TestimonialsComponentSectionSchema';
import Image from 'next/image';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import Testimonial from '@components/Molecules/Testimonial';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Section from '@components/Atoms/Section';
import SectionTitle from '@components/Atoms/SectionTitle';

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: TestimonialsComponentSectionSchema;
};

export default function TestimonialsSection({ component }: Props) {
  const randomId = 5;

  const testimonials = component.reviews.data.map((review, index) => {
    return <Testimonial index={index} key={index} review={review} />;
  });

  // @ts-ignore
  const carousel = (
    <Carousel autoPlay={true} rewind={true} responsive={responsive}>
      {testimonials}
    </Carousel>
  );

  return (
    <Section name="testimonials">
      <div className="container pt-5 pb-5">
        <SectionTitle>{component.Title}</SectionTitle>

        <div className="row">
          <div className="carousel-inner">{carousel}</div>
        </div>
      </div>
    </Section>
  );
}
