"use client";
import React, { useState } from "react";
import { GlobalProps } from "@lib/global";
import { HeroComponentSectionSchema } from "@lib/types/components/HeroComponentSectionSchema";
import { getLocalImageUrl } from "@lib/helpers/assetsHelpers";
import { useRouter } from "next/navigation";
import Section from "@components/Atoms/Section";
import { mapLines } from "@lib/helpers/parsers";
import { componentParser } from "@lib/componentParser";
import { ContactComponentSectionSchema } from "@lib/types/components/ContactComponentSectionSchema";
import { RoadmapComponentSectionSchema } from "@lib/types/components/RoadmapComponentSectionSchema";
import Script from "next/script";
import { ScheduleCallComponentSectionSchema } from "@lib/types/components/ScheduleCallComponentSectionSchema";

export type Props = {
  component: ScheduleCallComponentSectionSchema;
};

export default function ScheduleCallSection({ component }: Props) {
  return (
    <Section name="schedule-call">
      <Script src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></Script>
      <div className="container pt-3">
        <h1>{component.Title}</h1>
        <p>{component.Subtitle}</p>

        <div className="frame">
          <div
            className="meetings-iframe-container"
            data-src={component.HubspotUrl}
          ></div>
        </div>
      </div>
    </Section>
  );
}
