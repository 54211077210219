import React, { useState } from 'react';
import { GlobalProps } from '@lib/global';
import 'react-multi-carousel/lib/styles.css';
import { FaqPricingComponentSectionSchema } from '@lib/types/components/FaqPricingComponentSectionSchema';
import { FAQApiSchema } from '@lib/types/api/collections-types/FAQApiSchema';
import Section from '@components/Atoms/Section';
import SectionTitle from '@components/Atoms/SectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { openFaq } from '@app/features/faqs';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: FaqPricingComponentSectionSchema;
};

export default function FAQSection({ component }: Props) {
  const activeFaq = useSelector(state => (state as any).faqs.value);
  const dispatch = useDispatch();

  const faqSelected = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
    if (index === activeFaq) {
      dispatch(openFaq(undefined));
    } else {
      dispatch(openFaq(index));
    }
  };

  const faqLeftColumnLength = Math.ceil(component.faqs.data.length / 2);

  const renderFaqsColumn = (
    faqs: {
      id: number;
      attributes: FAQApiSchema;
    }[]
  ) =>
    faqs.map(faq => (
      <div className="accordion-item" key={faq.id}>
        <h6 className="accordion-header">
          <button
            onClick={faqSelected(faq.id)}
            className={'accordion-button' + (activeFaq === faq.id ? '' : ' collapsed')}
          >
            {faq.attributes.Title}
          </button>
        </h6>
        <div className={'accordion-collapse' + (activeFaq === faq.id ? '' : ' collapse')}>
          <div className="accordion-body">
            <p
              dangerouslySetInnerHTML={{
                __html: faq.attributes.Text,
              }}
            ></p>
          </div>
        </div>
      </div>
    ));

  return (
    <Section name="faqs">
      <div className="container pt-5 pb-5">
        <SectionTitle>{component.Title}</SectionTitle>

        <div className="accordions-wrapper d-flex">
          <div className="accordion">{renderFaqsColumn(component.faqs.data.slice(0, faqLeftColumnLength))}</div>
          <div className="accordion">{renderFaqsColumn(component.faqs.data.slice(faqLeftColumnLength))}</div>
        </div>
      </div>
    </Section>
  );
}
