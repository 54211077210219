import { ComponentsType } from '@lib/types/api/Components';
import Hero from '@components/Organisms/Hero';
import ClientLogosSection from '@components/Sections/ClientLogosSection';
import TitleWithIconsSection from '@components/Sections/TitleWithIconsSection';
import FeatureWithImageSection from '@components/Sections/FeatureWithImageSection';
import TitleWithThreeFeaturesSection from '@components/Sections/TitleWithThreeFeaturesSection';
import TestimonialsSection from '@components/Sections/TestimonialsSection';
import React from 'react';
import { GlobalProps } from '@lib/global';
import FAQSection from '@components/Sections/FAQSection';
import ContactUsSection from '@components/Sections/ContactUsSection';
import RoadmapSection from '@components/Sections/RoadmapSection';
import ScheduleCallSection from '@components/Sections/ScheduleCallSection';
import PricingSection from '@components/Sections/PricingSection';
import TextWithImageSection from '@components/Sections/TextWithImageSection';

export const componentParser = (globals: GlobalProps, components: ComponentsType[]): React.JSX.Element[] => {
  return components?.map((component, index) => {
    switch (component.__component) {
      case 'sections.hero':
        return <Hero key={index} component={component} globals={globals} />;
      case 'sections.logo-set':
        return (
          <div className="hides-hero-section" key={index}>
            <ClientLogosSection component={component} globals={globals} className="hides-hero-section" />
          </div>
        );
      case 'sections.title-with-icons':
        return (
          <div className="hides-hero-section" key={index}>
            <TitleWithIconsSection globals={globals} component={component} className="hides-hero-section" />
          </div>
        );
      case 'sections.feature-with-image':
        return (
          <div className="hides-hero-section" key={index}>
            <FeatureWithImageSection globals={globals} component={component} className="hides-hero-section" />
          </div>
        );
      case 'sections.text-with-image':
        return (
          <div className="hides-hero-section" key={index}>
            <TextWithImageSection globals={globals} component={component} className="hides-hero-section" />
          </div>
        );
      case 'sections.title-with-3-features-and-button':
        return (
          <div className="hides-hero-section" key={index}>
            <TitleWithThreeFeaturesSection globals={globals} component={component} className="hides-hero-section" />
          </div>
        );
      case 'sections.testimonials':
        return (
          <div className="hides-hero-section" key={index}>
            <TestimonialsSection globals={globals} component={component} className="hides-hero-section" />
          </div>
        );
      case 'sections.fa-qs':
        return <FAQSection key={index} component={component} globals={globals} />;
      case 'sections.contact':
        return <ContactUsSection key={index} component={component} globals={globals} />;
      case 'sections.roadmap':
        return <RoadmapSection component={component} key={index} />;
      case 'sections.schedule-call':
        return <ScheduleCallSection component={component} key={index} />;
      case 'sections.pricing':
        return <PricingSection component={component} key={index} />;
      default:
        return <code key={index}>UNKNOWN</code>;
    }
  });
};
