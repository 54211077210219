export const CONFIG = {
  path: 'https://strapi.costlocker.com/api',
  // path: 'http://127.0.0.1:1337/api',
  token:
    'b66ec21b4dc29cee3a77e545583b816234677f7c4e18a7934148f1494f60418d85a51a61e0fa90149dae52abf9d2f7ad61fcf24a7c4b87d85c642cc0a2d48bde62e907fa56590e54b81204f21dba0de31c20188e5e74e1c8d626db28baa009c385186886c3c115d566a6f35ad545facefadd265a4e4367d0c4c680a131a3f4b4',
  cdnPath: '',
  cache: process.env.CACHE ?? true,
};

// Dev token: de6d0ee01a24f835f4a5a10dfe5f76738ea6b9631e86d391f9bfdd192cde4dcda68eb43214bcb1e2cc69d8c101357750ac69dda3e6d687d47c3c28049e25c36d8b3297bbd9df857a11f55981bd969b00b9a878eeb5b85812a1fc4853a6fcdb2caf14cf6c9423485efba1ecb1a84d115ec08fb231104b79b7fda2d41e03c461e7
// Strapi token: b66ec21b4dc29cee3a77e545583b816234677f7c4e18a7934148f1494f60418d85a51a61e0fa90149dae52abf9d2f7ad61fcf24a7c4b87d85c642cc0a2d48bde62e907fa56590e54b81204f21dba0de31c20188e5e74e1c8d626db28baa009c385186886c3c115d566a6f35ad545facefadd265a4e4367d0c4c680a131a3f4b4
