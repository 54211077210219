import React from 'react';
import { GlobalProps } from '@lib/global';
import { getImageUrl } from '@lib/helpers/assetsHelpers';
import { ClientLogosComponentSectionSchema } from '@lib/types/components/ClientLogosComponentSectionSchema';
import Link from 'next/link';
import Section from '@components/Atoms/Section';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: ClientLogosComponentSectionSchema;
};

export default function ClientLogosSection({ component }: Props) {
  const Logos = component.LogoSet.data.attributes.ClientLogos.data.map(logo => {
    let content = (
      <div className="">
        <img
          title={logo.attributes.Name}
          className="img-fluid"
          style={{
            width: '100%',
          }}
          src={getImageUrl(logo.attributes.Logo.data?.attributes?.url ?? '')}
          alt="Hero"
        />
      </div>
    );

    if (logo.attributes.URL?.trim().length) {
      content = (
        <Link className="" href={logo.attributes.URL ?? '#'} target="_blank">
          <img
            title={logo.attributes.Name}
            className="img-fluid"
            style={{
              width: '100%',
            }}
            src={getImageUrl(logo.attributes.Logo.data?.attributes?.url ?? '')}
            alt="Hero"
          />
        </Link>
      );
    }

    return (
      <div key={logo.id} className="logo col-8 col-lg-4">
        {content}
      </div>
    );
  });

  return (
    <Section name="client-logo">
      <div className="container pt-3">
        <div className="row">
          <h2 className="headline-5">{component.Name}</h2>
        </div>
        <div className="row">{Logos}</div>
      </div>
    </Section>
  );
}
