import React from 'react';
import { GlobalProps } from '@lib/global';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import Link from 'next/link';
import Image from 'next/image';
import { TitleWithThreeFeaturesComponentSectionSchema } from '@lib/types/components/TitleWithThreeFeaturesComponentSectionSchema';
import Section from '@components/Atoms/Section';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: TitleWithThreeFeaturesComponentSectionSchema;
};

export default function TitleWithThreeFeaturesSection({ component }: Props) {
  const titleFormatted = component.Title.replaceAll(/__(.*?)__/g, "<span class='text-highlight-yellow'>$1</span>");

  const generateBenefit = (benefit: string) => {
    return <li>{benefit}</li>;
  };

  return (
    <Section name="call-to-action">
      <div
        className="container"
        style={{
          backgroundImage: `url('${getLocalImageUrl('icons-blue.webp')}')`,
        }}
      >
        <div className="d-flex justify-content-center">
          <h2 dangerouslySetInnerHTML={{ __html: titleFormatted }}></h2>
        </div>

        <Link
          href={component.ButtonLink}
          className="btn btn-secondary text-white btn-call-to-action"
          style={{ fontSize: '1.2rem' }}
        >
          {component.ButtonLabel}
        </Link>

        <ul className="list-group mark-check white">
          {generateBenefit(component.Feature1)}
          {generateBenefit(component.Feature2)}
          {generateBenefit(component.Feature3)}
        </ul>
      </div>
    </Section>
  );
}
