import React from 'react';
import { ReviewApiSchema } from '@lib/types/api/ReviewApiSchema';
import Image from 'next/image';
import { getImageUrl, getLocalImageUrl } from '@lib/helpers/assetsHelpers';

interface Props {
  review: ReviewApiSchema;
  index: number;
}

export default function Testimonial({ review, index }: Props) {
  const classNames = 'item' + ' key-' + index + (index === 0 ? ' active' : '');

  const logoEntity = review.attributes.CompanyLogo?.data?.attributes;

  let logo = null;

  if (logoEntity) {
    logo = (
      <Image
        key={'logo-' + index}
        src={getImageUrl(logoEntity.url ?? '')}
        alt=""
        className="logo"
        width={logoEntity.width ?? 0}
        height={logoEntity.height ?? 0}
      />
    );
  }

  let imagePerson = null;

  const authorImageEntity = review.attributes.AuthorImage?.data?.attributes;
  if (authorImageEntity) {
    imagePerson = (
      <Image
        key={'logo-' + index}
        src={getImageUrl(authorImageEntity.url ?? '')}
        alt=""
        className="person rounded-5"
        width={authorImageEntity.width ?? 0}
        height={authorImageEntity.height ?? 0}
      />
    );
  }

  return (
    <div className={classNames}>
      <div className="testimonial-card">
        <div className="card">
          <div className="card-body">
            <div className="logo-wrapper">{logo}</div>

            <div className="d-flex">
              {/*<Image*/}
              {/*  src={getLocalImageUrl("_icons/QuoteMark-mini.svg")}*/}
              {/*  alt=""*/}
              {/*  width={30}*/}
              {/*  height={30}*/}
              {/*  className="mini-quote"*/}
              {/*/>*/}

              <div className="content">
                <p className="subheading">{review.attributes.Text}</p>

                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">{imagePerson}</div>
                  <div className={['flex-grow-1', imagePerson ? 'ms-3' : ''].join(' ')}>
                    <h6>{review.attributes.Author}</h6>
                    <div className="position">{review.attributes.AuthorRole}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
