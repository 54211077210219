import React from 'react';
import { GlobalProps } from '@lib/global';
import { getIconUrl } from '@lib/helpers/assetsHelpers';
import { TitleWithIconsComponentSectionSchema } from '@lib/types/components/TitleWIthIconsComponentSectionSchema';
import Image from 'next/image';
import Section from '@components/Atoms/Section';
import SectionTitle from '@components/Atoms/SectionTitle';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: TitleWithIconsComponentSectionSchema;
};

export default function TitleWithIconsSection({ component }: Props) {
  const Icons = component.FeatureWithIcons.data.map(icon => {
    return (
      <div key={icon.id} className="item col-24 col-md-8 text-center">
        <div className="key-image mb-3">
          <Image fill alt={icon.attributes.Title} src={getIconUrl(icon.attributes.Icon)} />
        </div>
        <h3 className="text-primary">{icon.attributes.Title}</h3>
        <p>{icon.attributes.Text}</p>
      </div>
    );
  });

  const htmlText = component.Text.replaceAll('\\n', '<br>');

  return (
    <Section name="feature-boxes">
      <div className="container pt-5 pb-5">
        <div className="row text-center">
          <SectionTitle>{component.Title}</SectionTitle>
          <p dangerouslySetInnerHTML={{ __html: htmlText }}></p>
        </div>
        <div className="row">{Icons}</div>
      </div>
    </Section>
  );
}
