import React from 'react';
import { GlobalProps } from '@lib/global';
import { getImageUrl, getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import { FeatureWithImageComponentSectionSchema } from '@lib/types/components/FeatureWithImageComponentSectionSchema';
import Link from 'next/link';
import Image from 'next/image';
import Section from '@components/Atoms/Section';
import ReadMore from '@components/Atoms/ReadMore';
import { mapLines } from '@lib/helpers/parsers';

export type Props = {
  className?: string;
  globals: GlobalProps;
  component: FeatureWithImageComponentSectionSchema;
};

export default function FeatureWithImageSection({ component }: Props) {
  const titleFormatted = component.Title.replaceAll(/__(.*?)__/g, "<span class='text-highlight-yellow'>$1</span>");

  let readMore = component.DisplayButton ? (
    <ReadMore url={component.ButtonUrl} label={component.ButtonLabel}></ReadMore>
  ) : null;

  const rowClasses = component.ImagePosition === 'right' ? 'row d-lg-flex flex-row-reverse' : 'row';

  const features = mapLines(component.Text, (item, key) => {
    return <li key={key}>{item}</li>;
  });

  return (
    <Section name="text-with-image">
      <div className="container pt-5 pb-5">
        <div className={rowClasses}>
          <div className="box-img col-sm-24 col-md-14">
            <img
              className="w-100"
              src={getImageUrl(component.Image.data?.attributes.url ?? '')}
              alt={component.Title}
            />
          </div>
          <div className="box-text col-sm-24 col-md-10">
            <h2 dangerouslySetInnerHTML={{ __html: titleFormatted }}></h2>
            <ul className="list-group mark-check mb-3 subheader-features">{features}</ul>
            {readMore}
          </div>
        </div>
      </div>
    </Section>
  );
}
